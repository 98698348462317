/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */



/* Embed font family from Google Fonts. Font selected: Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html {
    font-family: 'Inter', sans-serif !important;
}


@import '../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '~swiper/swiper-bundle.css';
@import 'ngx-toastr/toastr';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'ckeditor5/ckeditor5.css';
@import 'ckeditor5/ckeditor5-content.css';

// @import '~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';

// @include mat-datetimepicker-theme($theme);

@import 'swiper/css/bundle';

@media print {
	body {
		margin: 0 !important;
	}
}

$small: 300px;
$medium: 900px;

input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.ck-editor__editable_inline {
    max-height: 60vh !important;
}

.error-snackbar {
    background-color: #FF757F; /* Replace with your desired color */
    color: #ffffff; /* Text color */
}

ngx-datatable.listing {
    font-family: 'Inter', sans-serif !important;
    box-shadow: none !important;
    height: calc(100vh - 200px);

    .visible {
        // @apply divide-y divide-gray-200 ;
    }

    datatable-header {
        .datatable-row-center {
            // @apply divide-x divide-gray-200
            @apply mt-2.5 bg-gray-500 divide-x divide-gray-200
        }
    }

    .datatable-header {
        background-color: #f9fafb;

        .datatable-header-cell {
            background-color: #f9fafb !important;
        }
    }

    datatable-scroller {

        // @apply divide-y divide-gray-200;
        .datatable-row-center {
            @apply divide-x divide-gray-100;
        }
    }

    .datatable-footer-inner {
        background-color: #f9fafb;
    }

    .datatable-header-cell {

        @apply text-xs p-2 uppercase font-semibold;

        .datatable-header-cell-label {
            color: #64748B;
            @apply text-md font-semibold
        }

        .datatable-header-cell-template-wrap {
            @apply items-center
        }
    }

    .datatable-body-cell {
        @apply text-sm p-2;
    }

    datatable-progress {
        .progress-linear {
            @apply z-10;

            .container {
                @apply bg-blue-300;

                .bar {
                    @apply bg-blue-600;
                }
            }
        }
    }
}

.darker-backdrop {
    background-color: rgba(0, 0, 0, 0.85) !important;
}

.lighter-backdrop {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.no-padding-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0px;
    }
}

// #region ng select
// Ng select theme
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";

.ng-select-container {
    height: 40px !important;
}

.ng-select {
    border: none;

    // .ng-select-container {
    //     @apply h-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus: ring-fuchsia-700 focus:border-fuchsia-700 block w-full p-2.5;
    // }
}

// #endregion

// #region MAT DIALOG

.mat-dialog-container-fullscreen {
    background-color: rgba(0, 0, 0, 0.85) !important;
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    border-radius: 0 !important;
}

.mat-dialog-container-fullscreen-no-border-no-padding {
    background-color: rgba(0, 0, 0, 0.85) !important;
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    border-radius: 0 !important;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 0 !important;
        padding: 0 !important
    }
}


// #endregion MAT DIALOG

.cdk-global-scrollblock {
    position: static !important;
    width: initial !important;
    overflow-y: inherit !important;
}

.canvas-container {
    // width: unset !important;
    height: calc(100vh - 100px) !important;

}

// .upper-canvas {
//     height: calc(100vh - 100px);
// }

.center-align-text {
    display: flex;
    align-items: center;
    /* Vertical alignment */
    justify-content: center;
}

.custom-mat-select-field {
    margin-top: 0.4rem;
    height: 3.85rem;
}

.ngx-toastr {
    padding: 15px 15px 15px 20px !important;
}

.toast-info {
    background-color: #D444BE !important;
    background-image: none !important;

    &:hover {
        box-shadow: 0 0 12px #775151;
        opacity: 1;
        cursor: pointer;
    }
}

.mediaThumbail {
    img {
        height: inherit;
        min-height: inherit;
        width: inherit;
        min-width: inherit;
        object-fit: cover;
    }
}

.image-preview-dialog {
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mdc-dialog__surface {
                padding: 0 !important;
                background-color: transparent !important;
                box-shadow: none !important;
            }
        }
    }
}

.image-carousel {
    max-width: 100vw !important;
    max-height: 100vw !important;
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mdc-dialog__surface {
                justify-content: center !important;
                padding: 0 !important;
                background-color: transparent !important;
                box-shadow: none !important;
            }
        }
    }
}

.campaign-tracker-custom-icon {
    transform: scale(2);
}

// .mat-datepicker-content-container {
//     flex-direction: row !important;
//     align-items: end !important;
//     column-gap: 8px !important;

// }

.mat-datepicker-content {
    background-color: white;
    border-radius: 16px !important;
    border: 1px solid #e9e9e9;

    .mat-calendar-body-selected {
        background-color: #D444BE !important;
        color: white !important;
    }

    .mat-calendar-body-today {
        border: 2px solid #350089 !important;
        border-spacing: 4px 4px !important;
    }

    .mat-calendar-body-disabled {
        cursor: not-allowed !important;
        .mat-calendar-body-cell-content {
            background-color: #e9e9e9 !important;
        }
    }
}

// .time-container{
//     padding-top: 0 !important;
//     margin-bottom: 50px !important;

// }:before {
//     height: 0 !important;
// }

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field .mdc-text-field--filled {
    .mat-mdc-form-field-flex {
        padding: 0 !important;
    }
}

// .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
//     display: none !important;
// }

.template-dialog {
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mdc-dialog__surface {
                padding: 0 !important;
                height: 500px !important;
            }
        }
    }
}

.template-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    min-height: 36px !important;
    height: 36px !important;
}

.template-search {
    .mat-mdc-text-field-wrapper {
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
        border-radius: 0 !important;
    }
}

.cdk-global-overlay-wrapper {
    overflow: auto !important;
}

.mat-mdc-dialog-container {
    max-height: 100vh !important;
}

@media (max-width: 820px) and (orientation: portrait), (max-width: 1180px) and (orientation: landscape) {
    .ipad-responsive-high {
        width: 100vw !important;
        max-width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh !important;

        .mat-mdc-dialog-container .mdc-dialog__surface {
            border-radius: 0 !important;
        }

        .mat-horizontal-stepper-header-container {
            display: none !important;
        }

    }
    .contract-emails {
        display: block !important;
    }
    .contract-label {
        display: none !important;
    }
    .general{
        font-size: 22px;
    }
    .countdown-days{
        font-size: 25px;
        padding: 4px 20px;
    }
}

@media (min-width: 821px) and (orientation: portrait), (min-width: 1181px) and (orientation: landscape) {
    .contract-emails {
        display: none !important;
    }

    .contract-label {
        display: block !important;
    }

    .countdown-days{
        font-size: 36px;
        padding: 4px 24px;
    }
}

.ng-select.ng-select-single .ng-select-container {
    height: 45px !important;
}

.fuse-horizontal-navigation-wrapper {
    column-gap: 8px;
    .fuse-horizontal-navigation-menu-item {
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 12px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(11.2px);
            -webkit-backdrop-filter: blur(11.2px);
        }
        .fuse-horizontal-navigation-item-active {
            background: rgba(255, 255, 255, 0.26);
            border-radius: 12px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(11.2px);
            -webkit-backdrop-filter: blur(11.2px);
            .fuse-horizontal-navigation-item-title-wrapper {
                .fuse-horizontal-navigation-item-title {
                    span {
                        color: #fff !important;
                    }
                }
            }

            mat-icon {
                svg {
                    color: #fff !important;
                }
            }
        }
    }
}

.client-map-view {
    .gm-style-mtc {
        display: none !important;
    }
}

.installer-accordian {
    .mat-expansion-panel-body {
        padding: 0 16px 8px !important;
    }
}

.proposal-campaign-detail-datepicker {
    .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
            padding-left: 0 !important;
            align-items: center !important;
            .mat-mdc-form-field-infix {
                input {
                    height: 18px !important;
                }
            }
        }
    }
  }

.proposal-campaign-financials {
    .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
        --tw-bg-opacity: 0 !important;
        background-color: transparent !important;
        border-radius: 0 !important;
        border: 0 !important;
        .mat-mdc-form-field-flex {
            padding: 0 !important;
            align-items: center !important;
            .mat-mdc-form-field-infix {
                min-height: 24px;
                input {
                    height: 24px !important;
                }
            }
        }
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }
}

.example-margin {
    .mdc-form-field {
        padding-right: 0px !important;
    }
}

.proposal-campaign-financials-select {
    .mat-mdc-select-trigger {
        .mat-mdc-select-arrow-wrapper {
            .mat-mdc-select-arrow {
                color: white !important;
            }
        }
    }
}

.gray-spinner {
    .mdc-circular-progress__indeterminate-container {
        .mdc-circular-progress__spinner-layer {
            .mdc-circular-progress__circle-clipper {
                svg {
                    stroke: #64748B !important;
                }
            }
        }
    }
}

.white-spinner {
    .mdc-circular-progress__indeterminate-container {
        .mdc-circular-progress__spinner-layer {
            .mdc-circular-progress__circle-clipper {
                svg {
                    stroke: #fff !important;
                }
            }
        }
    }
}

.campaign-detail-add-store-asset {
    .mat-expansion-panel-header {
        padding: 0 8px !important;
    }
    margin: 0 !important;
    .mat-expansion-panel-content {
        .mat-expansion-panel-body {
            padding: 0 8px 8px 8px !important;
        }
    }
}

.insertion-order{
    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description{
        display: block;
    }
}

.artwork {
    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
        display: block;
    }
}


.client-card-view-expansion-header {
    .mat-expansion-indicator::after {
        border-color: #292929 !important;
    }
}